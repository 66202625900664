import validate from "/usr/src/node_modules/.pnpm/nuxt@3.13.2_@types+node@22.6.1_eslint@9.11.1_rollup@4.22.4_sass@1.77.2_typescript@5.6.2_vite@5.4.7_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/usr/src/node_modules/.pnpm/nuxt@3.13.2_@types+node@22.6.1_eslint@9.11.1_rollup@4.22.4_sass@1.77.2_typescript@5.6.2_vite@5.4.7_vue-tsc@2.1.6/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "pension-product-lock": () => import("/usr/src/packages/pension/middleware/pension-product-lock.ts"),
  "is-stock-portfolio-type-valid": () => import("/usr/src/packages/stocks/middleware/is-stock-portfolio-type-valid.ts"),
  "authenticated-or-login": () => import("/usr/src/apps/base/src/middleware/authenticated-or-login.ts"),
  "existing-product-id": () => import("/usr/src/apps/base/src/middleware/existing-product-id.ts"),
  "hebrew-only": () => import("/usr/src/apps/base/src/middleware/hebrew-only.ts"),
  "not-authenticated-only": () => import("/usr/src/apps/base/src/middleware/not-authenticated-only.ts")
}