import routerOptions0 from "/usr/src/node_modules/.pnpm/nuxt@3.13.2_@types+node@22.6.1_eslint@9.11.1_rollup@4.22.4_sass@1.77.2_typescript@5.6.2_vite@5.4.7_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/usr/src/apps/base/src/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}